const WhatsAppButton = ({ productName }) => {
  const message = `اريد ان استفسر عن ${productName}`;
  const whatsappUrl = `https://wa.me/12022866122?text=${encodeURIComponent(
    message
  )}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="mb-5 mr-5 inline-flex items-center px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
    >
      استفسر
    </a>
  );
};

export default WhatsAppButton;
