import axios from "axios";
import { createContext, useEffect, useState } from "react";

const AppContext = createContext();

export const AppContextProvider = (props) => {
  const { children } = props;
  let [regions, setRegions] = useState({});
  let [flats, setFlats] = useState([]);
  const getRegions = async () => {
    const resp = await axios.get("https://api.millennium-eg.net/regions");
    return resp.data;
  };
  const getFlats = async () => {
    const resp = await axios.get("https://api.millennium-eg.net/flats");
    return resp.data;
  };
  useEffect(() => {
    getRegions().then((response) => {
      setRegions(response);
    });
    getFlats().then((resp) => {
      setFlats(resp);
    });
  }, []);

  return (
    <AppContext.Provider value={{ regions, flats }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
