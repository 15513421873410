import React from "react";
import { Link } from "react-router-dom";

import logo_icon_80 from "../../assets/images/logo-icon-80.png";
import {
  FaRegEnvelope,
  FaDribbble,
  FaLinkedin,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaRegFile,
  FaBehance,
  PiShoppingCart,
} from "../../assets/icons/icons";
import { FaPhone, FaTiktok } from "react-icons/fa6";

export default function FooterTwo() {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid grid-cols-1">
                <div className="text-center">
                  <h1 className="text-5xl text-white">Mellennium</h1>
                  <p className="max-w-xl mx-auto mt-8">
                    يتوفر لدينا شقق فندقية مفروشة بجميع أنحاء القاهرة بأسعار
                    مناسبة وتعد الأرخص والأفضل فى السوق للإيجار اليومي أو
                    الشهري. في المهندسين، الدقي، الشيخ زايد. نضمن لكم أفضل
                    الأسعار شقق شبابي وعائلات تتوفر فيها حرية المنزل وراحة
                    الفنادق.
                  </p>
                </div>

                <ul className="list-none space-x-1 text-center mt-8">
                  <li className="inline">
                    <Link
                      to="https://www.tiktok.com/@nassirmusa41?_t=8paFw3ph6hC&_r=1"
                      target="_blank"
                      className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                    >
                      <FaTiktok className="text-sm" />
                    </Link>
                  </li>
                  <li className="inline">
                    <Link
                      to="https://www.facebook.com/profile.php?id=61565380203524&mibextid=kFxxJD"
                      target="_blank"
                      className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                    >
                      <FaFacebookF className="text-sm" />
                    </Link>
                  </li>
                  <li className="inline">
                    <Link
                      to="https://www.instagram.com/mille_nnium9941?utm_source=qr&igsh=MTJ4MWFib3phdmprYQ=="
                      target="_blank"
                      className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                    >
                      <FaInstagram className="text-sm" />
                    </Link>
                  </li>
                </ul>

                <div className="flex gap-4 items-center justify-center mt-5" >
                    0012022866122 - 00201067751375  <FaPhone />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid md:grid-cols-1">
            <p className="mb-0">
              © {new Date().getFullYear()} Developed with{" "}
              <i className="mdi mdi-heart text-red-600"></i> by{" "}
              <Link
                to="https://www.facebook.com/profile.php?id=61564413371192"
                target="_blank"
                className="text-reset"
              >
                VistaWeb
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
