import React from "react";
import { Link } from "react-router-dom";

import { FiPhone } from "../assets/icons/icons";

export default function GetInTuch({ title }) {
  return (
    <div className="container relative md:mt-24 mt-16 " id="contact">
      <div className="grid grid-cols-1 text-center">
        {title === true ? (
          <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
            Contact Us
          </h6>
        ) : (
          ""
        )}
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
          هل لديك سؤال؟ ابق على تواصل معنا
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          نحن هنا لمساعدتك! إذا كان لديك أي استفسار أو ترغب في معرفة المزيد عن
          خدماتنا، فلا تتردد في الاتصال بنا. فريقنا جاهز للرد على جميع أسئلتك
          وتقديم الدعم الذي تحتاجه. يمكنك التواصل معنا عبر الهاتف، واتساب ، أو
          زيارة مكاتبنا في أي وقت خلال ساعات العمل. نسعد بخدمتك ونتطلع إلى سماعك
          قريبًا.
        </p>

        <div className="mt-6">
          <a
            href="https://wa.me/12022866122?text=اهلا%20ميلينيوم%20اتواصل%20معك%20من%20خلال%20الموقع%20واريد%20استفسارا"
            className="py-2 px-5 inline-flex font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4"
          >
            <FiPhone className="me-1 text-lg" /> اتصل بنا
          </a>
        </div>
      </div>
    </div>
  );
}
