import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footerTwo";

import * as Icon from "react-feather";
import {
  LiaCompressArrowsAltSolid,
  LuBath,
  LuBedDouble,
} from "../../assets/icons/icons";

import { shopGrid } from "../../data/dataTwo";
import { realEstate } from "../../data/dataFour";
import AppContext from "../../context/AppContext";
import WhatsAppButton from "../../component/Whats";

export default function ShopGridTwo() {
  const [shopGridData, setShopGridData] = useState(shopGrid);
  const { regions, flats } = useContext(AppContext);

  return (
    <>
      <Navbar />

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="w-full">
            {/*grid md:grid-cols-12 grid-cols-1 gap-[30px]*/}
            {/* <div className="lg:col-span-4 md:col-span-6">
              <div className="shadow dark:shadow-gray-800 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                <form>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <label className="font-semibold">المناطق</label>
                      {shopGridData.map((data, index) => {
                        return (
                          <div className="block mt-2" key={index}>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  onClick={() => onClickCategory(data.gender)}
                                  className="form-radio border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                                  name="radio-colors"
                                  checked={data.checked}
                                  value="1"
                                  readOnly
                                />
                                <span className="text-slate-400">
                                  {data.gender}
                                </span>
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="mt-2">
                      <input
                        type="submit"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
                        value="Apply Filter"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            <div className="lg:col-span-8 md:col-span-6">
              <div className="">
                <div className="lg:col-span-9 md:col-span-8">
                  <h3 className="text-5xl text-center leading-normal font-semibold">
                    النتائج
                  </h3>
                  <p className="text-slate-400 mt-4 text-center">
                    في صفحة الإقامات لدينا، نقدم لك مجموعة متنوعة من الخيارات
                    التي تناسب جميع احتياجاتك. سواء كنت تبحث عن إقامة قصيرة
                    الأمد أو طويلة الأمد، فنحن نقدم لك خيارات متعددة تناسب كل
                    الميزانيات والمواقع.
                  </p>
                  <p className="text-slate-400 mt-4 text-center">
                    استكشف قائمة الإقامات المتاحة وتعرف على التفاصيل الدقيقة لكل
                    عقار. لدينا عقارات مميزة في مواقع متنوعة، مع خدمات ممتازة
                    ومرافق حديثة لضمان راحتك واستمتاعك بإقامتك.
                  </p>
                  <p className="text-slate-400 mt-4 text-center">
                    لا تتردد في الاتصال بنا للحصول على المزيد من المعلومات أو
                    لحجز الإقامة التي تناسبك. فريقنا المتخصص هنا لمساعدتك في
                    اختيار الأفضل وتقديم دعم مستمر طوال فترة إقامتك.
                  </p>
                </div>
              </div>

              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-20 w-full ">
                {flats.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="group rounded-md bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-96"
                    >
                      <div className="relative">
                        <img src={data.image_url} alt="" />

                        <div className="absolute top-6 end-6">
                          {/* <Link
        to="#"
        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-lg text-center bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600"
      >
        <i className="mdi mdi-heart"></i>
      </Link> */}
                        </div>

                        {/* Rating and Price */}
                        <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent text-white">
                          <ul className="flex justify-between items-center">
                            <li>
                              {/* <span className="text-slate-300">Price</span> */}
                              <p className="text-lg font-medium">
                                {data.price} ج.م
                              </p>
                            </li>
                            <li>
                              {/* <span className="text-slate-300">Rating</span> */}
                              <ul className="text-lg font-medium text-amber-400 list-none flex items-center">
                                <li className="inline">
                                  <i className="mdi mdi-star"></i>
                                </li>
                                <li className="inline">
                                  <i className="mdi mdi-star"></i>
                                </li>
                                <li className="inline">
                                  <i className="mdi mdi-star"></i>
                                </li>
                                <li className="inline">
                                  <i className="mdi mdi-star"></i>
                                </li>
                                <li className="inline">
                                  <i className="mdi mdi-star"></i>
                                </li>
                                <li className="inline text-white">
                                  5.0({Math.round(Math.random() * 100)})
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="p-6">
                        <div className="pb-6">
                          <Link
                            to="#"
                            className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500"
                          >
                            {data.title}
                          </Link>
                        </div>
                        <small className="text-sm mb-5">
                          العنوان : {data.address}
                        </small>
                        <small className="text-sm mb-5 block">
                          المنطقة :{" "}
                          {
                            regions.filter(
                              (r) => parseInt(r.id) == parseInt(data.c_id)
                            )[0].name
                          }
                        </small>
                        <details className="mt-5">
                          <summary>الوصف</summary>
                          {data.desc}
                        </details>
                        <ul className="py-6 border-y border-gray-100 dark:border-gray-800 flex flex-wrap items-center list-none">
                          <li className="flex items-center me-4">
                            <LiaCompressArrowsAltSolid className="text-2xl me-2 text-indigo-600" />
                            <span className="text-sm">{data.area}</span>
                          </li>

                          <li className="flex items-center me-4">
                            <LuBedDouble className="text-2xl me-2 text-indigo-600" />
                            <span className="text-sm">{data.rooms} غرف</span>
                          </li>

                          <li className="flex items-center">
                            <LuBath className="text-2xl me-2 text-indigo-600" />
                            <span className="text-sm">
                              {data.bathrooms} مراحيض
                            </span>
                          </li>
                        </ul>
                      </div>
                      <WhatsAppButton productName={data.title} />
                    </div>
                  );
                })}
              </div>
              {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                <div className="md:col-span-12 text-center">
                  <nav aria-label="Page navigation example">
                    <ul className="inline-flex items-center -space-x-px">
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <MdOutlineKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <MdKeyboardArrowRight className="text-xl rtl:rotate-180 rtl:-mt-1" />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* <MobileApp/> */}
      </section>
      <Footer />
    </>
  );
}
