import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";

import IndexRealEstate from "./pages/index/indexRealEstate";
import { AppContextProvider } from "./context/AppContext";
import ShopGridTwo from "./pages/ecommerce/shopGridTwo";

export default function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IndexRealEstate />} />
          <Route path="/flats" element={<ShopGridTwo />} />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}
